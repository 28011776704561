<script setup lang="ts">
import { showToast } from 'vant'
import icon_close from '~/assets/image/icon/icon_close.png'
import { TaskApi } from '~/net/apis/task_api'

const router = useRouter()
const onClickLeft = () => history.back()
const list = ref()

const showtask = ref(false)
const content = ref()
const confirmText = ref('立即前往')
// const userStore = useUser()

function unDoneShowTxt(model: TaskCenterQuery) {
  if (model.status === 2) {
    // 2 "已完成已领取"
    model.statusLabel = '已完成'
  } else if (model.status === 1) {
    // 1 任务进行中
    model.statusLabel = '进行中'
  } else if (model.status === 3) {
    // 3 任务完成待领取
    model.statusLabel = '领取奖励'
  } else {
    switch (model.taskTypeId ?? 0) {
      case 50:
        model.statusLabel = '立即签到' // 立即签到
        break
      case 1:
        model.statusLabel = '立即观影' // 立即观影  观影奖励（分）
        break
      case 2:
        model.statusLabel = '立即观影' // 立即观影 观影奖励（次)
        break
      case 3:
        model.statusLabel = '立即评论' // 立即评论  评论奖励
        break
      case 4:
        model.statusLabel = '立即收藏' // 立即收藏  影片收藏"
        break
      case 5:
        model.statusLabel = '立即点赞' // 立即点赞   影片点赞
        break
      case 6:
        model.statusLabel = '立即缓存' // 立即缓存
        break
      case 7:
        model.statusLabel = '观看广告' // 观看广告
        break
      case 101:
        model.statusLabel = '立即绑定' // 立即绑定
        break
      case 201:
        model.statusLabel = '累计签到任务' // 累计签到任务
        break
      default:
        model.statusLabel = '查看规则' // 查看规则
        break
    }
  }
}

async function queryTaskCenterList() {
  const { data } = await TaskApi.queryTaskCenterList()
  console.log('data', data)
  list.value = data.map(item => {
    unDoneShowTxt(item)
    return item
  })
}
queryTaskCenterList()
function showTask(item: TaskCenterQuery) {
  if (item.status === 2 || item.status === 1) {
    return
  }
  if (item.status === 3) {
    _doneTask(item)
  } else {
    _unDoneTask(item)
  }
}
function _unDoneTask(model: TaskCenterQuery) {
  showtask.value = true
  content.value = model
  if (model.taskTypeId === 50)
  {
    confirmText.value = '关闭提示'
  }
}

function confirmHandle() {
  const model = content.value
  const resultText = model.taskTypeId
  showtask.value = false
  if (resultText === 101) { // 是否绑定手机"我的-个人-绑定手机"
    const isBindMobile = !!useUser().userInfo.mobile
    router.push({ path: '/bindPhone', query: { arguments: isBindMobile ? '绑定新手机号' : '绑定手机号' } })
  } else if (resultText > 0 && resultText < 50) {
    router.push('/')
  } else if (resultText === 50 && resultText === 201) {
    // 签到任务
  }
}
/// 完成任务 领取奖励
async function _doneTask(model: TaskCenterQuery) {
  const { data } = await TaskApi.dailTaskDraw({ id: model.id, drawType: `${model.taskTypeId}` })
  showToast(data)
  _setTaskStatusTxt(model)
}

function _setTaskStatusTxt(model: TaskCenterQuery) {
  model.status = 2
  model.statusLabel = '已完成'
}
</script>

<template>
  <div h-screen>
    <van-nav-bar title="任务中心" left-arrow @click-left="onClickLeft" />
    <div px-4>
      <div flex flex-col>
        <div
          v-for="(item, index) in list"
          :key="index"
          mb-4
          h-18
          w-full
          flex
          items-center
          justify-between
          rounded-md
          ct-ffffff
          class="taskBg"
        >
          <div ml-8>
            <p>{{ item.taskName }}</p>
            <p text-xs>{{ item.taskInfo }}</p>
          </div>
          <div
            v-if="item.status !== 1 && item.status !== 2"
            mr-4
            h-11
            w-28
            rounded-md
            text-center
            leading-11
            gradient-fd9403-fd5b03-130.94
            @click="showTask(item)"
          >
            {{ item.statusLabel }}
          </div>
          <div v-else mr-4 h-11 w-28 rounded-md text-center leading-11 bg-6d6d6d>
            {{ item.statusLabel }}
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="showtask">
      <div h-full flex items-center justify-center>
        <div w-80 bg-111425>
          <div relative h-10 w-full text-center leading-10>
            <span text-lg ct-ffffff>{{ content.taskName }}</span>
            <img :src="icon_close" absolute right-2 top-1 h-4 w-4 @click="showtask = false" />
          </div>
          <div mt-4>
            <div mt-2 h-5 w-full flex flex-col items-center justify-center ct-e8e8e8>
              <div h-4 w-50 text-center text-xs leading-6>{{ content.taskInfo }}</div>
            </div>
            <div m-4 flex justify-center>
              <div h-11 w-68 text-center ct-ffffff class="bg-bt" @click="confirmHandle">{{ confirmText }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
.taskBg {
  background: url('~/assets/image/icon/taskBg.png') no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-bt {
  background: linear-gradient(130.94deg, #fd9403 16.87%, #fd5b03 89.42%);
  line-height: 44px;
  border-radius: 10px;
}
</style>
